import React, * as react from 'react';
import './JDArtEng.css'

class JDArtSpa extends react.Component {

    openWindow(url) {
        return window.open(url, "Popup",
           "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=1200, height=700, top=30"
        );
    };

    render() {
        return (
            <>
                <div className="container-fluid container-md">
                    <div className="pagetitle">Articles (Spanish)</div>
                    <div>

                        <div className="artcategory">Technology</div>
                        <ul>
                            <li><div className="article" onClick={()=>this.openWindow('./images/padrescomputadoras.jpg')}>Los Padres de la Computadora Moderna (<em>The Fathers of the Modern Computer</em>)<br/>
                            <em>Sunday edition of Listin Diario, a New York based Spanish newspaper</em></div></li>
                            <li><div className="article" onClick={()=>this.openWindow('https://jdejesus001.medium.com/llevando-la-educación-a-distancia-a-hispanoamérica-600177c8d47f')}>Llevando la Educaci&oacute;n a Distancia a Hispanoam&eacute;rica<br/>
                            <em>Colegio Hispano Irland&eacute;s - Misioneros de Lumen Dei</em></div></li>
                        </ul>

                        
                    </div>
                </div>
            </>

        );
    }
}

export default JDArtSpa;