import React, * as react from 'react';
import './JDMission.css'

class JDMission extends react.Component {

    openWindow(url) {
        return window.open(url, "Popup",
           "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=1200, height=700, top=30"
        );
    };

    render() {
        return (
            <>
                <div className="container-fluid container-md">
                <div className="pagetitle">Mission Work</div>
                    <div className="aboutme">
                     <div className="aboutmecontent">   
                     <p>
                        In December of 2003, my wife and I decided to take our family on a 14-day mission trip with Catholic missionaries
                        to Peru, specifically to a town called Ollantaytambo in Cusco. We figured this would be a once in a lifetime experience for our children, something that would 
                        help mold their outlook on life and heighten their sense of responsibility toward others early on.
                    </p>
                    <p>
                        Below is a picture of my wife and I serving a hot protein-based drink to some children. 
                        <p>&nbsp;</p>
                        <center><img src="./images/lumendei.jpg" alt="cusco" width="70%" height="70%"/></center>
                     </p>  
                     <p>
                     That experience left an indelible mark on us. We knew we would never be the same.
                     In August of 2004, after careful consideration, I asked for a one year leave of absence from IBM to help the missionaries further.
                     Our family moved to Madrid, where they were headquartered, so that I could lead the design and development of
                     a distance learning system based on the Shareable Content Object Reference Model (SCORM). My children went to school there
                     and my wife taught English. Although we were not on the frontline with the poor, this became a very practical way for us to contribute
                     our time and talent for a good cause.
                     </p>
                     </div>    
                    </div>
                </div>
            </>

        );
    }
}

export default JDMission;