import React, * as react from 'react';

class JDSidebar extends react.Component {


    render() {
        return (
            <>
            
            <div className="border-end bg-white" id="sidebar-wrapper">
                {/* <div className="sidebar-heading border-bottom bg-light">Start Bootstrap</div> */}
                <div className="list-group list-group-flush">
                    <div style={{borderBottom: "1px solid #1759E2", backgroundColor: "#f7f7f7"}}>
                    <div style={{color:"#070707", textAlign: "center", fontSize:"0.77em", paddingTop: "5px"}}>Need to Contact Me?</div>
                    <div style={{color:"#0d6efd", textAlign: "center", fontSize:"0.7em", paddingBottom:"3px"}}><a href="mailto:jose@josedejesus.net">jose@josedejesus.net</a></div>
                    <div style={{color:"#0d6efd", textAlign: "center", fontSize:"0.7em", paddingBottom:"3px"}}><a href="mailto:jdejesus@us.ibm.com">jdejesus@us.ibm.com</a></div>
                    </div>
                    <br/>
                    <div style={{color: "#1C60FF", paddingTop:"12px", paddingLeft:"12px", fontSize:"0.7rem", textTransform:"uppercase", borderBottom: "1px solid #f3f3f3"}}>Main</div>
                    <a className="list-group-item list-group-item-action list-group-item-light p-3" href="/profile">Professional Profile</a>
                    <a className="list-group-item list-group-item-action list-group-item-light p-3" href="/softskills">Soft Skills</a>
                    <a className="list-group-item list-group-item-action list-group-item-light p-3" href="/techskills">Technical Skills</a>
                    <a className="list-group-item list-group-item-action list-group-item-light p-3" href="/refletters">Reference Letters</a>
                    <div style={{color: "#1C60FF", paddingTop:"12px", paddingLeft:"12px", fontSize:"0.7rem", textTransform:"uppercase", borderBottom: "1px solid #f3f3f3"}}>Credentials</div>
                    <a className="list-group-item list-group-item-action list-group-item-light p-3" href="/academic">Academic</a>
                    <a className="list-group-item list-group-item-action list-group-item-light p-3" href="/digital">Digital Badges</a>
                    {/* <a className="list-group-item list-group-item-action list-group-item-light p-3" style={{color:"lightgray"}} href="#!"><em>Patents (wip)</em></a> */}
                    <a className="list-group-item list-group-item-action list-group-item-light p-3" href="/certs">Additional</a>
                    <div style={{color: "#1C60FF", paddingTop:"12px", paddingLeft:"12px", fontSize:"0.7rem", textTransform:"uppercase", borderBottom: "1px solid #f3f3f3"}}>Publications</div>
                    <a className="list-group-item list-group-item-action list-group-item-light p-3" href="/books">Books</a>
                    <a className="list-group-item list-group-item-action list-group-item-light p-3" href="/arteng">Articles (English)</a>
                    <a className="list-group-item list-group-item-action list-group-item-light p-3" href="/artspa">Articles (Spanish)</a>
                    <a className="list-group-item list-group-item-action list-group-item-light p-3" href="/podcasts">Podcasts</a>
                    <a className="list-group-item list-group-item-action list-group-item-light p-3" href="/conferences">Conferences</a>
                    <a className="list-group-item list-group-item-action list-group-item-light p-3" href="/anim">Animated Videos</a>
                    {/* <a className="list-group-item list-group-item-action list-group-item-light p-3" style={{color:"lightgray"}} href="#!"><em>Conferences (wip)</em></a> */}
                    {/* <div style={{color: "#1C60FF", paddingTop:"12px", paddingLeft:"12px", fontSize:"0.7rem", textTransform:"uppercase", borderBottom: "1px solid #f3f3f3"}}>Websites</div>
                    <a className="list-group-item list-group-item-action list-group-item-light p-3" href="/wsactive">Active</a>
                    <a className="list-group-item list-group-item-action list-group-item-light p-3" href="/wsactive#wip">Under Development</a>
                    <a className="list-group-item list-group-item-action list-group-item-light p-3" href="/wsactive#parked">Parked Domains</a> */}
                    <div style={{color: "#1C60FF", paddingTop:"12px", paddingLeft:"12px", fontSize:"0.7rem", textTransform:"uppercase", borderBottom: "1px solid #f3f3f3"}}>Personal Bits &amp; Pieces</div>
                    <a className="list-group-item list-group-item-action list-group-item-light p-3" href="/firstpc">My First Computer</a>
                    {/* <a className="list-group-item list-group-item-action list-group-item-light p-3" href="/wsactive">Websites</a> */}
                    <a className="list-group-item list-group-item-action list-group-item-light p-3" href="/mission">Mission Work</a>
                    <a className="list-group-item list-group-item-action list-group-item-light p-3" href="/aboutme"><em>POV: On Successful Teams</em></a>
                </div>
            </div>
            </>
        );
    }
}

export default JDSidebar;