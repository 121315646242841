import React, * as react from 'react';
import './JDConf.css'

class JDConf extends react.Component {

    openWindow(url) {
        return window.open(url, "Popup",
            "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=1200, height=700, top=30"
        );
    };

    render() {

        return (
            <>
                <div className="container-fluid container-md">
                    <div className="pagetitle">Conferences</div>
                    <div className="fpmaintext">

                        <div className="grid-container">
                            <a href="/refletters"><div className="books"><img width='115px' height='150px' src='./images/ibmconf.jpg' alt="ibmconf" /><p>&nbsp;</p>Presented at Various IBM Conferences<br/>Between 1998 and 2018<br/>&nbsp;<br/>Please See Reference Letters.</div></a>
                            <a href="#!" onClick={() => this.openWindow('/images/respaiconf.png')}><div className="books"><img width='260px' height='150px' src='./images/respaiconf.png' alt="respaiconf" /><p>&nbsp;</p><em>Speaker</em><br/>Presented the ROI in AI Ethics Calculator<br/>I had created<br/>University of Notre Dame Ethics Lab</div></a>
                            <a href="#!" onClick={() => this.openWindow('/images/dsconf.jpg')}><div className="books"><img width='260px' height='150px' src='./images/dsconf.jpg' alt="dsconf" /><p>&nbsp;</p><em>Moderator</em><br/>Brain Storming Session 2<br/>Data Science and Analytics Conference<br/>Florida Atlantic University (FAU)</div></a>
                            <a href="#!" onClick={() => this.openWindow('/images/cuc96.jpg')}><div className="books"><img width='260px' height='150px' src='./images/cuc96.jpg' alt="cuc96" /><p>&nbsp;</p><em>Presenter</em><br/>First Component User's Conference<br/>Munich 1996<br/>&nbsp;</div></a>
                            <a href="#!" onClick={() => this.openWindow('/images/objconf.jpg')}><div className="books"><img width='260px' height='150px' src='./images/objconf.jpg' alt="rb2" /><p>&nbsp;</p><em>Presenter<br/></em>Object Expo - Introducing Java Expo<br/>New York 1996<br/>&nbsp;</div></a>
                        </div>


                    </div>
                </div>
            </>
        );

        // return (
        //     <>
        //         <div className="container-fluid container-md">
        //             <div className="pagetitle">Books</div>
        //             <div>
        //                 <p><br/>
        //                     <ul>
        //                         {/* <li><div className="article" onClick={() => this.openWindow('https://jdejesus001.medium.com/what-is-machine-learning-ac2f14dea42e')}>RedBook SME Contribution - Creating Composite Application Patterns Models for IBM PureApplication System</div></li> */}
        //                         <li><div className="article"><em>RedBook SME Contribution</em> - Creating Composite Application Patterns Models for IBM PureApplication System</div></li>
        //                         <li><div className="article"><em>RedBook SME Contribution</em> - Establishing a Secure Hybrid Cloud with the IBM PureApplication Family</div></li>
        //                         <li><div className="article">Thomas Jell, (Contributing Authors: Jos&eacute; De Jes&uacute;s, et al): CUC 96 Component Based Software Engineering, in: Book, Cambridge University Press, 1998,
        //                             ISBN/ISSN: 0-521-64821-1</div></li>
        //                         <li><div className="article">Jos&eacute; De Jes&uacute;s, Abad Godoy: Borland Pascal With Objects 7.0, in: Book, MIS:Press, 704 pages, 1993, ISBN/ISSN: 1-55828-247-5</div></li>
        //                     </ul>
        //                 </p>
        //             </div>
        //         </div>
        //     </>

        // );
    }
}

export default JDConf;