import React, * as react from 'react';
import skills from './images/techskills.jpg';
import './JDTechSkills.css';

class JDTechSkills extends react.Component {

    openWindow(url) {
        return window.open(url, "Popup",
           "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=1200, height=700, top=30"
        );
    };
    render() {
        return (
            <>
            <div className="container-fluid container-md">
               <div className="pagetitle">Technical Skills</div>
               <br/>
               <div className="techskills">
                  <div className="skills">
                    <img alt="skills" src={skills} width="70%" height="70%"/>
                  </div>
                </div>
            </div>
            </>
        );
    }
}

export default JDTechSkills;