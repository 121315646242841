import React, * as react from 'react';
import './JDSoftSkills.css'

class JDSoftSkills extends react.Component {

    openWindow(url) {
        return window.open(url, "Popup",
            "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=1200, height=700, top=30"
        );
    };

    render() {
        return (
            <>
                <div className="container-fluid container-md">
                    <div className="pagetitle">Soft Skills</div>
                    <div className="fpmaintext">
                        <ul>
                            <li>Demonstrates <strong>leadership by example</strong>, fostering a strong work ethic in teams.</li>
                            <li><strong>Excels in communication</strong>, ensuring clarity and alignment across diverse stakeholders.</li>
                            <li><strong>Drives results</strong> through effective teamwork and collaboration, inspiring shared success.</li>
                            <li>Applies <strong>emotional intelligence</strong> to resolve conflicts, adapt to challenges, and build resilience in dynamic environments.</li>
                            <li><strong>Innovates with creativity and self-motivation</strong>, consistently generating fresh ideas and solutions.</li>
                            <li><strong>Employs critical thinking</strong> to streamline complex processes, eliminate ambiguity, and deliver impactful outcomes.</li>
                        </ul>
                    </div>
                </div>
            </>

        );
    }
}

export default JDSoftSkills;