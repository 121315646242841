import React, * as react from 'react';
import './JDTopNavigation.css';
// import './css/layout.css'
// import './css/bloodwiz.css'
// import './css/zkin-ui-kit.min.css'
import jose from './images/joseround.jpg';


class JDTopNavigation extends react.Component {

    openWindow(url) {
        return window.open(url, "Popup",
            "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=1200, height=700, top=30"
        );
    };

    render() {
        return (
            <>
                <div className="header">
                    <div className="content">
                        <nav className="navbar navbar-expand-lg navbar-light bg-light">
                            {/* <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom"> */}
                            <div className="container-fluid">

                                <button id="sidebarToggle">Toggle Menu</button>
                                {/* <button className="btn btn-primary" id="sidebarToggle">Toggle Menu</button> */}
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>


                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    {/* <ul className="nav navbar-nav ml-auto"> */}
                                    <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
                                        {/* <li className="nav-item"><div className="nav-link" href="#!">Invitation Code:&nbsp;<span>JD4PKG2022</span></div></li> */}
                                        <li className="nav-item"><div className="nav-link" style={{ position: "relative", top: "7px" }} href="#!">Last updated on&nbsp;<span>March 28, 2023&nbsp;&nbsp;&nbsp;</span></div></li>
                                        <li className="nav-item">
                                            {/* <button style={{position:"relative", top:"8px"}} className="btn btn-primary" href="#!" onClick={() => this.openWindow('./pdf/JDResume-AUG-18-2021.pdf')}>Download R&eacute;sum&eacute; (PDF)</button> */}
                                            {/* <div className="container"> */}

                                            <div class="dropdown">
                                                <button class="btn btn-primary dropdown-toggle" style={{position:"relative", top:"8px"}} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    Download R&eacute;sum&eacute;
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li><div href="#!" onClick={() => this.openWindow('./pdf/JDResumeExternal-MAR-28-2023.pdf')}>Public</div></li>
                                                    <li><div href="#!" onClick={() => this.openWindow('./pdf/JDResumeExternal-MAR-28-2023.pdf')}><em>IBM Internal Only</em></div></li>
                                                </ul>
                                            </div>
                                            {/* <button id="sidebarToggle">Download Resume (PDF)</button> */}
                                            {/* </div> */}
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                    <div className="section position-relative page-header page-title">
                        <div className="banner-wrapper d-flex position-absolute w-100 h-100">


                            <div className="container">
                                <div className="banner-caption-wrapper align-self-center text-center w-100 p-4">
                                    <div className="row">
                                        <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                                            <div className='jdtitle'>Jos&eacute; De Jes&uacute;s</div>
                                            <div className="headerimg"><img src={jose} alt="jose" /></div>
                                            <div className='subtitleframe'>&nbsp;</div>
                                            <div className="subtitle">Innovative, Results-driven, Client-obsessed Leader</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}

export default JDTopNavigation;