import React, * as react from 'react';
import './JDRefLetters.css';

class JDRefLetters extends react.Component {

    openWindow(url) {
        return window.open(url, "Popup",
            "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=1200, height=700, top=30"
        );
    };
    render() {
        return (
            <>
               <div className="pagetitle">Reference Letters Archive</div>
               <div className="maintext">
               <div className="firsttext"><br/>Here are some letters of recommendation from past work I've done:<br/></div>
               <div className="grid-container">
                <a href="#!" onClick={()=>this.openWindow('/pdf/JDEJESUS-TLRBSupportLetter-KyleBrown.pdf')}><div className="refletter">Kyle Brown<br/><span>IBM Fellow,<br/>VP, CTO for the CIO</span></div></a>
                {/* <a href="#!" onClick={()=>this.openWindow('/pdf/JDEJESUS-KyleBrown.pdf')}><div className="refletter">Kyle Brown (2)<br/><span>IBM Fellow,<br/>VP, CTO for the CIO</span></div></a> */}
                <a href="#!" onClick={()=>this.openWindow('/pdf/JDEJESUS-TLRBSupportLetter-RachelReinitz.pdf')}><div className="refletter">Rachel Reinitz<br/><span>IBM Fellow,<br/>VP, Founder &amp; CTO IBM Garage</span></div></a>
                <a href="#!" onClick={()=>this.openWindow('/pdf/JDEJESUS-TLRBSupportLetter-SavioRodrigues.pdf')}><div className="refletter">Savio Rodrigues<br/><span>Vice President<br/>Red Hat Marketplace</span></div></a>
                <a href="#!" onClick={()=>this.openWindow('/pdf/JDEJESUS-TLRBSupportLetter-GaryBurnette.pdf')}><div className="refletter">Gary Burnette<br/><span>Vice President<br/>EDG, Global Chief Data Officer</span></div></a>
                <a href="#!" onClick={()=>this.openWindow('/pdf/JDEJESUS-TLRBSupportLetter-RickFricchione.pdf')}><div className="refletter">Rick Fricchione<br/><span>VP, Senior Partner, CTO<br/>Enterprise Applications Service Line</span></div></a>
                <a href="#!" onClick={()=>this.openWindow('/pdf/JDEJESUS-TLRBSupportLetter-DianeCopenhaver.pdf')}><div className="refletter">Diane Copenhaver<br/><span>Director, Payments<br/>IBM Commerce</span></div></a>
                <a href="#!" onClick={()=>this.openWindow('/pdf/JDEJESUS-TLRBSupportLetter-MannyAmorim.pdf')}><div className="refletter">Manny Amorim<br/><span>Retired IBMer, Vice President<br/>WW System Middleware Services</span></div></a>
                <a href="#!" onClick={()=>this.openWindow('/pdf/JDEJESUS-TLRBSupportLetter-VishwanathNarayan.pdf')}><div className="refletter">Vishwanath Narayan<br/><span>IBM Distinguished Engineer,<br/>CTO AIAPPS Expert Lab Services</span></div></a>
                <a href="#!" onClick={()=>this.openWindow('/pdf/JDEJESUS-TLRBSupportLetter-TomCreamer.pdf')}><div className="refletter">Tom Creamer<br/><span>IBM Distinguished Engineer<br/>CTO Global Markets &amp; Red Hat Synergy</span></div></a>
                <a href="#!" onClick={()=>this.openWindow('/pdf/JDEJESUS-TLRBSupportLetter-RockySingh.pdf')}><div className="refletter">Rocky Singh<br/><span>IBM Distinguished Engineer<br/>Distribution, CAI GBS</span></div></a>
                <a href="#!" onClick={()=>this.openWindow('/pdf/JDEJESUS-TLRBSupportLetter-YaroslavDunchych.pdf')}><div className="refletter">Yaroslav Dunchych<br/><span>IBM Distinguished Engineer<br/>Garage Tech Solution Acceleration</span></div></a>
                <a href="#!" onClick={()=>this.openWindow('/pdf/JDEJESUS-TLRBSupportLetter-PeterBahrs.pdf')}><div className="refletter">Peter Bahrs<br/><span>Retired IBMer,<br/>PhD, DE, SCITA, MI</span></div></a>
                <a href="#!" onClick={()=>this.openWindow('/pdf/JDEJESUS-TLRBSupportLetter-JeanPommier.pdf')}><div className="refletter">Jean Pommier<br/><span>IBM Distinguished Engineer<br/>Global Markets</span></div></a>
                <a href="#!" onClick={()=>this.openWindow('/pdf/JDEJESUS-TLRBSupportLetter-JoseOrtiz.pdf')}><div className="refletter">Jose Ortiz<br/><span>IBM Distinguished Engineer<br/>CTO Hybrid Cloud Security</span></div></a>
                <a href="#!" onClick={()=>this.openWindow('/pdf/JDEJESUS-TLRBSupportLetter-AndreTost.pdf')}><div className="refletter">Andre Tost<br/><span>IBM Distinguished Engineer<br/>CTO IBM Cloud Pak Architecture</span></div></a>
                <a href="#!" onClick={()=>this.openWindow('/pdf/JDEJESUS-TLRBSupportLetter-BobbyWoolf.pdf')}><div className="refletter">Bobby Woolf<br/><span>Cloud Solution Architect<br/>Worldwide Ecosystems</span></div></a>
                <a href="#!" onClick={()=>this.openWindow('/pdf/JDEJESUS-TLRBSupportLetter-LinSun.pdf')}><div className="refletter">Lin Sun<br/><span>Director of Open Source<br/>solo.io</span></div></a>
                <a href="#!" onClick={()=>this.openWindow('/pdf/JDEJESUS-TLRBSupportLetter-AmadoNassif.pdf')}><div className="refletter">Amado Nassif<br/><span>Program Director<br/>WebSphere DataPower Development</span></div></a>
                <a href="#!" onClick={()=>this.openWindow('/pdf/JDEJESUS-TLRBSupportLetter-AshokIyengar.pdf')}><div className="refletter">Ashok Iyengar<br/><span>Executive Cloud Architect<br/>Master Inventor</span></div></a>
                <a href="#!" onClick={()=>this.openWindow('/pdf/JDEJESUS-TLRBSupportLetter-ErneseNorelus.pdf')}><div className="refletter">Ernese Norelus<br/><span>Associate Partner<br/>Synpulse Management Consulting</span></div></a>
                <a href="#!" onClick={()=>this.openWindow('/pdf/JDEJESUS-TLRBSupportLetter-BenedictFernandes.pdf')}><div className="refletter">Benedict Fernandes<br/><span>Global BI Tiger Team<br/>Cloud &amp; Cognitive Software</span></div></a>
                <a href="#!" onClick={()=>this.openWindow('/pdf/JDEJESUS-TLRBSupportLetter-IrenePenney.pdf')}><div className="refletter">Irene Penney<br/><span>Retired IBMer, STSM<br/>Exec Arch, Office of the CIO</span></div></a>
                <a href="#!" onClick={()=>this.openWindow('/pdf/JDEJESUS-MannyAthavale.pdf')}><div className="refletter">Manohar Athavale<br/><span>Retired IBMer,<br/>Former Principal E&amp;T Services</span></div></a>
                <a href="#!" onClick={()=>this.openWindow('/pdf/JDEJESUS-DanielDuffy.pdf')}><div className="refletter">Daniel Duffy<br/><span>Retired IBMer,<br/>PM, AIS Wirless and Emerging Technologies</span></div></a>
                <a href="#!" onClick={()=>this.openWindow('/pdf/JDEJESUS-JaneScofield.pdf')}><div className="refletter">Jane Scofield<br/><span>Delivery Project Executive<br/>IBM Singapore</span></div></a>
                <a href="#!" onClick={()=>this.openWindow('/pdf/JDEJESUS-TLRBSupportLetter-JuanCaraballo.pdf')}><div className="refletter">Juan Caraballo<br/><span>Program Director, Latin America Grid<br/>Corporate University &amp; Innovation Programs</span></div></a>
                <a href="#!" onClick={()=>this.openWindow('/pdf/JDEJESUS-TLRBSupportLetter-SudeepDewan.pdf')}><div className="refletter">Sudeep Dewan<br/><span>Director<br/>O2O Execution &amp; Sales Transformation</span></div></a>
                <a href="#!" onClick={()=>this.openWindow('/pdf/JDEJESUS-Mahender.pdf')}><div className="refletter">Mahender Kumar<br/><span>Certified IT Architect<br/>Client Process Transformation S&amp;D</span></div></a>
                <a href="#!" onClick={()=>this.openWindow('/pdf/JDEJESUS-KarenStanhope.pdf')}><div className="refletter">Karen Stanhope<br/><span>Certified IT Architect<br/>Hartford Insurance Cluster</span></div></a>
                <a href="#!" onClick={()=>this.openWindow('/pdf/JDEJESUS-KathyBazinet.pdf')}><div className="refletter">Kathy Bazinet<br/><span>Technical Sales Manager<br/>Financial Services Market</span></div></a>
                <a href="#!" onClick={()=>this.openWindow('/pdf/JDEJESUS-MarcGardy-NYSTAX.pdf')}><div className="refletter">Marc Gardy<br/><span>Manager, Technical Services<br/>NYS Dept of Taxation &amp; Finance</span></div></a>
                <a href="#!" onClick={()=>this.openWindow('/pdf/JDEJESUS-MarioBolo.pdf')}><div className="refletter">Mario Bolo<br/><span>Chief Technologist<br/>IBM Innovation Network</span></div></a>
                <a href="#!" onClick={()=>this.openWindow('/images/JDEJESUS-BLADEX-ANAESCOBAR.jpg')}><div className="refletter">Ana Escobar<br/><span>Vice President &amp; Deputy General Manager<br/>Banco Latinoamericano de Exportaciones</span></div></a>
                   
                </div>
               </div> 
            </>
        );
    }
}

export default JDRefLetters;