import React, * as react from 'react';
import './JDFirstPC.css'

class JDFirstPC extends react.Component {

    openWindow(url) {
        return window.open(url, "Popup",
           "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=1200, height=700, top=30"
        );
    };

    render() {
        return (
            <>
                <div className="container-fluid container-md">
                <div className="pagetitle">My First Computer</div>
                    <div className="aboutme">
                     <div className="aboutmecontent">   
                     <p>
                        My first computer was a TRS-80 Model 4, which my older brother purchased for me with his freshly minted Radio Shack credit card. 
                        This was a dream come true. I spent many long hours hacking, and learning how to program, and developed
                        an animated hangman program which we would enjoy playing during family gatherings. <br/>&nbsp;<br/>
                        <center><img alt="trs80pocket" src="./images/trs80model4.png" /></center>
                        <br/>&nbsp;<br/>
                        Technically, my first computer was a pocket-computer which I won for solving a logic
                        problem at my high school's annual schoolwide logic contest:<br/>&nbsp;<br/>
                        <center><img alt="trs80pocket" src="./images/trs80pocket.jpg" /></center>
                        <br/>
                        Although it was fully programmable in Pocket-BASIC, I really considered it more of a fancy calculator. The important
                        thing is that those few years helped me define what I wanted to do long-term. At the age of 15, I entered Fordham University to study
                        Computer Science.
                        </p>
                     </div>    
                    </div>
                </div>
            </>

        );
    }
}

export default JDFirstPC;