import React, * as react from 'react';
import './JDBooks.css'

class JDBooks extends react.Component {

    openWindow(url) {
        return window.open(url, "Popup",
            "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=1200, height=700, top=30"
        );
    };

    render() {

        return (
            <>
                <div className="container-fluid container-md">
                    <div className="pagetitle">Books</div>
                    <div className="fpmaintext">

                        <div className="grid-container">
                            <a href="#!" onClick={() => this.openWindow('/images/opensourceaibook.png')}><div className="books"><img width='120px' height='150px' src='./images/opensourceaibook.png' alt="opensourceaibook" /><p>&nbsp;</p>Jos&eacute; De Jes&uacute;s, Jerry Cuomo: Open Source AI, 2025</div></a>
                            <a href="#!" onClick={() => this.openWindow('/images/jdbp.jpg')}><div className="books"><img width='235px' height='150px' src='./images/jdbp.jpg' alt="jdbp" /><p>&nbsp;</p>Jos&eacute; De Jes&uacute;s, Abad Godoy: Borland Pascal With Objects 7.0, 704 pages, 1993</div></a>
                            <a href="#!" onClick={() => this.openWindow('/images/cuc.jpg')}><div className="books"><img width='325px' height='150px' src='./images/cuc.jpg' alt="cuc" /><p>&nbsp;</p><em>Chapter Contribution</em> - CUC 96 Component Based Software Engineering, Cambridge University Press, 1998</div></a>
                            <a href="#!" onClick={() => this.openWindow('/images/JDIBMRedbook1.jpg')}><div className="books"><img width='205px' height='150px' src='./images/JDIBMRedbook1.jpg' alt="rb1" /><p>&nbsp;</p><em>SME Contribution</em> - Establishing a Secure Hybrid Cloud with...</div></a>
                            <a href="#!" onClick={() => this.openWindow('/images/JDIBMRedbook2.jpg')}><div className="books"><img width='215px' height='150px' src='./images/JDIBMRedbook2.jpg' alt="rb2" /><p>&nbsp;</p><em>SME Contribution</em> - Creating Composite Application Patterns Models...</div></a>
                        </div>


                    </div>
                </div>
            </>
        );

        // return (
        //     <>
        //         <div className="container-fluid container-md">
        //             <div className="pagetitle">Books</div>
        //             <div>
        //                 <p><br/>
        //                     <ul>
        //                         {/* <li><div className="article" onClick={() => this.openWindow('https://jdejesus001.medium.com/what-is-machine-learning-ac2f14dea42e')}>RedBook SME Contribution - Creating Composite Application Patterns Models for IBM PureApplication System</div></li> */}
        //                         <li><div className="article"><em>RedBook SME Contribution</em> - Creating Composite Application Patterns Models for IBM PureApplication System</div></li>
        //                         <li><div className="article"><em>RedBook SME Contribution</em> - Establishing a Secure Hybrid Cloud with the IBM PureApplication Family</div></li>
        //                         <li><div className="article">Thomas Jell, (Contributing Authors: Jos&eacute; De Jes&uacute;s, et al): CUC 96 Component Based Software Engineering, in: Book, Cambridge University Press, 1998,
        //                             ISBN/ISSN: 0-521-64821-1</div></li>
        //                         <li><div className="article">Jos&eacute; De Jes&uacute;s, Abad Godoy: Borland Pascal With Objects 7.0, in: Book, MIS:Press, 704 pages, 1993, ISBN/ISSN: 1-55828-247-5</div></li>
        //                     </ul>
        //                 </p>
        //             </div>
        //         </div>
        //     </>

        // );
    }
}

export default JDBooks;