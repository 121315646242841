import React, * as react from 'react';
import './JDPodcasts.css'

class JDPodcasts extends react.Component {

    openWindow(url) {
        return window.open(url, "Popup",
            "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=1200, height=700, top=30"
        );
    };

    render() {

        return (
            <>
                <div className="container-fluid container-md">
                    <div className="pagetitle">Podcasts</div>
                    <div className="fpmaintext">

                        <div className="grid-container">
                            <a href="https://ivypodcast.com/episode/strategic-leadership-through-digital-transformation-with-jose-de-jesus-cto-of-automation-for-expert-labs-at-ibm/" onClick={() => this.openWindow('/images/ivypodcast.png')}><div className="books"><img width='380px' height='150px' src='./images/ivypodcast.png' alt="podcasts" /><p>&nbsp;</p><em>Guest Speaker</em><br/>Strategic Leadership Through Digital Transformation with Jose De Jesus<br/>Ivy Podcasts</div></a>
                        </div>


                    </div>
                </div>
            </>
        );

        // return (
        //     <>
        //         <div className="container-fluid container-md">
        //             <div className="pagetitle">Books</div>
        //             <div>
        //                 <p><br/>
        //                     <ul>
        //                         {/* <li><div className="article" onClick={() => this.openWindow('https://jdejesus001.medium.com/what-is-machine-learning-ac2f14dea42e')}>RedBook SME Contribution - Creating Composite Application Patterns Models for IBM PureApplication System</div></li> */}
        //                         <li><div className="article"><em>RedBook SME Contribution</em> - Creating Composite Application Patterns Models for IBM PureApplication System</div></li>
        //                         <li><div className="article"><em>RedBook SME Contribution</em> - Establishing a Secure Hybrid Cloud with the IBM PureApplication Family</div></li>
        //                         <li><div className="article">Thomas Jell, (Contributing Authors: Jos&eacute; De Jes&uacute;s, et al): CUC 96 Component Based Software Engineering, in: Book, Cambridge University Press, 1998,
        //                             ISBN/ISSN: 0-521-64821-1</div></li>
        //                         <li><div className="article">Jos&eacute; De Jes&uacute;s, Abad Godoy: Borland Pascal With Objects 7.0, in: Book, MIS:Press, 704 pages, 1993, ISBN/ISSN: 1-55828-247-5</div></li>
        //                     </ul>
        //                 </p>
        //             </div>
        //         </div>
        //     </>

        // );
    }
}

export default JDPodcasts;