import React, * as react from 'react';
import './JDFrontPage.css'

class JDFrontPage extends react.Component {

    openWindow(url) {
        return window.open(url, "Popup",
           "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=1200, height=600, top=30"
        );
     };

    render() {
        return (
            <>
                <div className="container-fluid container-md">
                    <div className="pagetitle">Professional Profile</div>
                    <div className="fpmaintext">
                            <ul>
                                <li>IBM CTO of Automation and Certified Executive Architect with over 30 years of experience in IT and a consistent record of overachievement in roles ranging from CTO IBM Automation Expert Labs, Americas Delivery Lead for Garage Expert Labs, Chief Architect for IBM Software Adoption, Cloud Expert &amp; Certification Lead, Senior Software Engineer, and Business Integration Specialist.</li>
                                <li>Thought Leader responsible for defining the technical landscape that leaders across IBM Expert Labs use to guide clients in automation, from business automation, to integration, AIOps, and network automation.</li>
                                <li>Responsible for designing IBM Expert Labs’ overall automation mission and strategic goals, as well as overseeing the development and dissemination of technologies and reusable assets that can help our clients reach straight-through processing.</li>
                                <li>Specialization in Cloud architecture, design, adoption, and deployment, with a proven ability to jump start solutions and successfully drive them to production.</li>
                                <li>Key driver of Cloud technologies and Profession Certifications for IBM’s Expert Labs as well as the focal point for expanding IBM’s internal adoption of Automation Software on all strategic IBM Hybrid Cloud and Cognitive platforms.</li>
                                <li>Member of the IBM Open Innovation Community (formerly known as the IBM Academy of Technology), founded in 1989 as a community of top IBM talent to provide innovation, technical advice and critiques as well as help shape the technical underpinnings of IBM’s future.</li>
                                <li>Recipient of several IBM awards including <em>IBM AoT President Award (2018), Invention/Achievement, developerWorks Professional Author,  Eminence &amp; Excellence, Bravo, Outstanding Technical Achievement, and Outstanding Contributor.</em></li>
                                <li>Published author, and regular presenter at internal and external IBM Technical conferences.</li>
                                <li>Native command of English and Spanish.</li>
                            </ul>
                    </div>
                </div>
            </>

        );
    }
}

export default JDFrontPage;