import React, * as react from 'react';
import './JDWebsites.css';

class JDWebsites extends react.Component {

    openWindow(url) {
        return window.open(url, "Popup",
            "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=1400, height=700, top=30"
        );
    };
    render() {
        return (
            <>
                <div className="pagetitle">Websites</div>
                <div style={{ paddingLeft: "30px", color: "#777" }} id="active" className="artcategory">Active</div>
                <div className="grid-container">
                    <a href="#!" onClick={() => this.openWindow('http://hlpatibm.mybluemix.net')}><div className="webactive">Hyper-Learning Platform (HLP)<br /><span>http://hlpatibm.mybluemix.net</span></div></a>
                    <a href="#!" onClick={() => this.openWindow('http://silverstonks.io')}><div className="webactive">Silver Stonks<br /><span>http://silverstonks.io</span></div></a>
                </div>
                <div style={{ paddingLeft: "30px", paddingBottom:"1px", color: "#777", fontSize:"1.3em" }} id="active" className="artcategory"><em>Active, but needs revamping:</em></div>
                <div sytle={{paddingTop:"0px"}} className="grid-container">
                    <a href="#!" onClick={() => this.openWindow('http://overlaptime.com')}><div className="webactive">Overlap Time<br /><span>overlaptime.com</span></div></a>
                    {/* <a href="#!" onClick={() => this.openWindow('http://silverstonks.io')}><div className="webactive">Silver Stonks<br /><span>silverstonks.io<br/>Providing technical guidance</span></div></a> */}
                    <a href="#!" onClick={() => this.openWindow('http://bloodwiz.com')}><div className="webactive">Blood Wiz<br /><span>bloodwiz.com</span></div></a>
                    <a href="#!" onClick={() => this.openWindow('http://qubitsandpieces.com')}><div className="webactive">Qubits &amp; Pieces<br /><span>qubitsandpieces.com</span></div></a>
                </div>
                <div style={{ paddingLeft: "30px", color: "#777" }} id="wip" className="artcategory">Under Development</div>
                <div className="grid-container">
                    <a href="#!" onClick={() => this.openWindow('http://openshiftcheatsheet.com')}><div className="webwip">OpenShift Cheat Sheet<br /><span>openshiftcheatsheet.com</span></div></a>
                    <a href="#!" onClick={() => this.openWindow('http://cloudpakcheatsheets.com')}><div className="webwip">Cloud Pak Cheat Sheets<br /><span>cloudpakcheatsheet.com</span></div></a>
                    <a href="#!"><div className="webinactive">Hyperautomation Track<br /><span>hyperautomationtrack.com</span></div></a>
                    <a href="#!"><div className="webinactive">Business Automation Track<br /><span>businessautomationtrack.com</span></div></a>
                    <a href="#!"><div className="webinactive">RPA Track<br /><span>rpatrack.com</span></div></a>
                    <a href="#!"><div className="webinactive">AIOps Track<br /><span>aiopstrack.com</span></div></a>
                    <a href="#!"><div className="webinactive">Cognitive Track<br /><span>cognitivetrack.com</span></div></a>
                    <a href="#!"><div className="webinactive">The Cloud Track<br /><span>thecloudtrack.com</span></div></a>
                    <a href="#!"><div className="webinactive">Quantum Terms<br /><span>quantumterms.com</span></div></a>
                    <a href="#!"><div className="webinactive">Quantum Glossary<br /><span>quantumglossary.com</span></div></a>
                </div>
                <div style={{ paddingLeft: "30px", color: "#777" }} id="parked" className="artcategory">Parked Domains</div>
                <div>
                    <ul style={{color: "#ddd"}}>
                        <li>aimlcorner.com</li>
                        <li>aimlserver.com</li>
                        <li>aimltrack.com</li>
                        <li>aimltutorials.com</li>
                        <li>aiorbiter.com</li>
                        <li>aivocab.com</li>
                        <li>aivocabulary.com</li>
                        <li>artofcompilers.com</li>
                        <li>brainorbiter.com</li>
                        <li>cicdpath.com</li>
                        <li>cicdtrack.com</li>
                        <li>cloud-paterns.net</li>
                        <li>cloudorgs.com</li>
                        <li>cloudpakcheatsheet.com</li>
                        <li>cloudpatterns.info</li>
                        <li>cloudpatterns.site</li>
                        <li>cloudpatternsblog.com</li>
                        <li>cloudtrack.online</li>
                        <li>compilerart.com</li>
                        <li>compileroptions.com</li>
                        <li>covid19wiz.com</li>
                        <li>criticaloverlap.com</li>
                        <li>dataorbiter.com</li>
                        <li>datascienceorgs.com</li>
                        <li>devopstrack.com</li>
                        <li>doctorqubit.com</li>
                        <li>dsorgs.com</li>
                        <li>emisari.net</li>
                        <li>emisari.org</li>
                        <li>expertdynamix.com</li>
                        <li>fiestainvite.com</li>
                        <li>findcheatsheet.com</li>
                        <li>findcheatsheets.com</li>
                        <li>findoverlap.com</li>
                        <li>geekgiveback.com</li>
                        <li>geekhoo.com</li>
                        <li>gitintro.com</li>
                        <li>gitstudy.com</li>
                        <li>gitstudysheet.com</li>
                        <li>hlpproject.com</li>
                        <li>hybridlearningplatform.com</li>
                        <li>hybridlearningplatform.org</li>
                        <li>intelligentautomationtrack.com</li>
                        <li>javahoo.com</li>
                        <li>jdejesus.info</li>
                        <li>jdejesus.net</li>
                        <li>learncompilers.com</li>
                        <li>learnjenkins.com</li>
                        <li>learnwatson.com</li>
                        <li>linkeroptions.com</li>
                        <li>machinelearningpatterns.com</li>
                        <li>machinelearningterms.com</li>
                        <li>machinelearningtrack.com</li>
                        <li>machinelearningvocab.com</li>
                        <li>machinelearningvocabulary.com</li>
                        <li>masm128.com</li>
                        <li>microservicestrack.com</li>
                        <li>middlewaretrack.com</li>
                        <li>mindorbiter.com</li>
                        <li>missionmercycharity.com</li>
                        <li>misterqubit.com</li>
                        <li>mlorbiter.com</li>
                        <li>mlpatterns.com</li>
                        <li>mlvocab.com</li>
                        <li>mlvocabulary.com</li>
                        <li>myaitwin.com</li>
                        <li>nodetalent.com</li>
                        <li>ocpcheatsheet.com</li>
                        <li>openshifttalent.com</li>
                        <li>overlaptime.com</li>
                        <li>paintingpals.org</li>
                        <li>pawbeachtreats.com</li>
                        <li>pubseek.net</li>
                        <li>pubtrack.com</li>
                        <li>quantumcompilers.com</li>
                        <li>quickstudysheet.com</li>
                        <li>quickstudysheets.com</li>
                        <li>rhcheatsheet.com</li>
                        <li>rhcheatsheets.com</li>
                        <li>rhocpcheatsheet.com</li>
                        <li>sacredheartchurchfamily.com</li>
                        <li>santamariasoftware.com</li>
                        <li>sellmydomain.org</li>
                        <li>thebigdatatrack.com</li>
                        <li>thecollegetea.com</li>
                        <li>thedevopstrack.com</li>
                        <li>thelittlemh.com</li>
                        <li>thelittlemontessorihouse.com</li>
                        <li>themobiletrack.com</li>
                        <li>thestartuptea.com</li>
                        <li>tlmh.online</li>
                        <li>usewatson.com</li>
                        <li>verbumcaritatis.com</li>
                        <li>wasm64.com</li>
                        <li>wasm64.org</li>
                        <li>watsonchat.com</li>
                        <li>webcradle.com</li>
                        <li>webhlp.org</li>
                        <li>workloadadvisor.com</li>
                        <li>workloadadvisor.net</li>
                        <li>yquehago.com</li>
                        <li>yqueledigo.com</li>
                    </ul>
                </div>
            </>
        );
    }
}

export default JDWebsites;