import React, * as react from 'react';
import './JDAboutMe.css'

class JDAboutMe extends react.Component {

    openWindow(url) {
        return window.open(url, "Popup",
           "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=1200, height=700, top=30"
        );
    };

    render() {
        return (
            <>
                <div className="container-fluid container-md">
                    <div style={{textAlign: "center", paddingTop: "3px"}}><img src="./images/joseaboutme.jpg" width='42%' height='42%' alt='jose' /></div>
                    <div className="aboutme">
                     <div className="aboutmecontent">   
                     <p>
                        Hello there!  Thank you for visiting my page. Here is a little more about me and some thoughts on what I believe makes teams successful:
                    {/* </p>
                     <div className="abouttitle">On Successful Teams</div>
                     <p> */}
                        <br/>&nbsp;<br/>
                        When I was a child, I dreamed of inventing many things--an amphibian car that could fly and be submerged,
                        a water-to-oxygen mask, a flying chair, and even a time machine. As a teenager, my favorite magazine was Popular Mechanics, and, later,
                        PC Magazine and Dr. Dobbs' Journal. I also loved reading books, especially fantasy novels and science fiction. Novels like <em>The Hobbit</em>, by J.R.R. Tolkien 
                        and <em>2001: A Space Odyssey</em> by Arthur Clarke fueled my imagination.
                    </p>
                    <p>
                        Passion for technology, innovation, and creativity has always driven me, but throughout the years, my lens has widened: how technology can
                        be applied to real world solutions and understanding who will ultimately benefit from that technology 
                        matters to me just as much. I have spent most of my career working with advanced
                        technologies as well as rousing the collective and creative energy of others to help create solutions that solve real problems.
                        Behind any new feature or design, there must be a measurable value, a vision into how
                        it will help accelerate a client's strategic journey and transformation, and a staunch commitment to delivering with
                        impact.
                    </p>
                    <p>
                        There is also the team. To me, <em>results-driven</em> does not mean squeezing a team to get things done no matter what. It's about <em>inspiring</em> the team to deliver with excellence always.
                        A team performs exponentially when members are treated with respect, deeply believe in the vision, and trust 
                        their leader. The leader must genuinely care about the team, give members the opportunity to grow, and lead by example. A quote from the book <em>A Leader's Legacy</em> by James M. Kouzes and Barry Z. Posner puts 
                        it best: <em><span>"...in the end, it is not about what you achieved for yourself but what you achieved for others. Not how
                        big a campfire you built but how well you kept others warm."</span></em> I might add: take care of the team and, in turn, the team will ensure
                        that campfire keeps burning.
                    </p>
                    <p>    
                        Individuals must be entrusted with new things, new leaders must emerge, and the
                        team's excellent performance must scale to new projects. Ultimately, success means not only that you delivered beyond the 
                        client's expectations but that you were also able to establish a solid relationship of mutual trust, with teams on both sides eager and ready to venture into
                        new things.
                    </p>
                    </div>

                    
                    {/* <div className="abouttitle">My First Computer</div>
                    <p>
                        When I was a child, I dreamed of inventing many things--an amphibian car that could fly and be submerged,
                        a water-to-oxygen mask, a flying chair, and even a time machine. As a teenager, my favorite magazine was Popular Mechanics, and, later,
                        PC Magazine and Dr. Dobbs' Journal. I also loved reading books, especially fantasy novels and science fiction. Novels like <em>The Hobbit</em>, by J.R.R. Tolkien 
                        and <em>2001: A Space Odyssey</em> by Arthur Clarke fueled my imagination.
                    </p>
                    <p>
                        My first computer was a TRS-80 Model 4, which my older brother purchased for me with his freshly minted Radio Shack credit card. 
                        This was a dream come true for me and I spent many long hours hacking, and learning how to program. I developed
                        an animated hangman program which we would enjoy playing during family gatherings. <br/>&nbsp;<br/>
                        <center><img alt="trs80pocket" src="./images/trs80model4.png" /></center>
                        <br/>&nbsp;<br/>
                        Technically, my first computer was a pocket-computer which I won for solving a logic
                        problem at my high school's annual schoolwide logic contest:<br/>&nbsp;<br/>
                        <center><img alt="trs80pocket" src="./images/trs80pocket.jpg" /></center>
                        <br/>
                        Although it was fully programmable in Pocket-BASIC, I really considered it more of a fancy calculator.
                         */}
                    {/* </p>  */}
                    {/* <div className="abouttitle">My First Few Jobs</div>
                    I graduated from High School and entered Fordham University at the age of 15 to study Computer Science.
                    A close friend of mine and I went job hunting during the summer and one of our very first jobs was
                    doing data entry at the United Negro College Fund in New York. I will never forget how well we were treated
                    there. I mention this because it truly left a lasting impression on the powerful effect of great team work.
                    This was a well-run oil machine.  */}
                    <p>                  
                        
                        
                    </p>
                    
                    
                </div>
                </div>
            </>

        );
    }
}

export default JDAboutMe;