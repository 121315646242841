import React, * as react from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch
} from "react-router-dom";
import JDSidebar from './JDSidebar';
import JDTopNavigation from './JDTopNavigation'
import JDFrontPage from './JDFrontPage';
import JDSoftSkills from './JDSoftSkills';
import JDTechSkills from './JDTechSkills';
import JDRefLetters from './JDRefLetters';
import JDAcademic from './JDAcademic';
import JDBooks from './JDBooks';
import JDConf from './JDConf';
import JDArtEng from './JDArtEng';
import JDArtSpa from './JDArtSpa';
import JDPodcasts from './JDPodcasts';
import JDAnim from './JDAnim';
import JDDigital from './JDDigital';
import JDCerts from './JDCerts';
import JDWebsites from './JDWebsites';
import JDFirstPC from './JDFirstPC';
import JDMission from './JDMission';
import JDAboutMe from './JDAboutMe';

import './JDMain.css';

class JDMain extends react.Component {

    componentDidMount() {

    }

    render() {
        return (
            <> 
            <div className="d-flex" id="wrapper">
                <JDSidebar />
                   <div id="page-content-wrapper">
                      <JDTopNavigation />
            
                         <Router>
                            <Switch>
                               <Route exact path='/' component={JDFrontPage} />
                            </Switch>
                            <Switch>
                               <Route exact path='/profile' component={JDFrontPage} />
                            </Switch>
                            <Switch>
                               <Route exact path='/softskills' component={JDSoftSkills} />
                            </Switch>
                            <Switch>
                               <Route exact path='/techskills' component={JDTechSkills} />
                            </Switch>
                            <Switch>
                               <Route exact path='/refletters' component={JDRefLetters} />
                            </Switch>
                            <Switch>
                               <Route exact path='/academic' component={JDAcademic} />
                            </Switch>
                            <Switch>
                               <Route exact path='/books' component={JDBooks} />
                            </Switch>
                            <Switch>
                               <Route exact path='/conferences' component={JDConf} />
                            </Switch>
                            <Switch>
                               <Route exact path='/arteng' component={JDArtEng} />
                            </Switch>
                            <Switch>
                               <Route exact path='/artspa' component={JDArtSpa} />
                            </Switch>
                            <Switch>
                               <Route exact path='/podcasts' component={JDPodcasts} />
                            </Switch>
                            <Switch>
                               <Route exact path='/digital' component={JDDigital} />
                            </Switch>
                            <Switch>
                               <Route exact path='/anim' component={JDAnim} />
                            </Switch>
                            <Switch>
                               <Route exact path='/certs' component={JDCerts} />
                            </Switch>
                            <Switch>
                               <Route exact path='/wsactive' component={JDWebsites} />
                            </Switch>
                            <Switch>
                               <Route exact path='/firstpc' component={JDFirstPC} />
                            </Switch>
                            <Switch>
                               <Route exact path='/mission' component={JDMission} />
                            </Switch>
                            <Switch>
                               <Route exact path='/aboutme' component={JDAboutMe} />
                            </Switch>
                         </Router>

                   </div>      
            </div>
            </>

        );
    }
}

export default JDMain;