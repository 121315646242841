import React, * as react from 'react';
import './JDArtEng.css'

class JDArtEng extends react.Component {

    openWindow(url) {
        return window.open(url, "Popup",
           "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=1200, height=700, top=30"
        );
    };

    render() {
        return (
            <>
                <div className="container-fluid container-md">
                    <div className="pagetitle">Articles (English)</div>
                    <div>

                        <div className="artcategory">AI & Machine Learning</div>
                        <ul>
                            <li><div className="article" onClick={()=>this.openWindow('https://jdejesus001.medium.com/a-balanced-approach-to-open-source-ai-49976b39dc7d')}>Why Open-Source AI Matters</div></li>
                            <li><div className="article" onClick={()=>this.openWindow('https://jdejesus001.medium.com/are-we-nearing-the-turing-horizon-936ef206cf08')}>Beyond the Turing Horizon</div></li>
                            <li><div className="article" onClick={()=>this.openWindow('https://jdejesus001.medium.com/a-short-introduction-to-generative-ai-697b2e293cc9')}>A Brief Introduction to Generative AI — Part 1 of 3</div></li>
                            <li><div className="article" onClick={()=>this.openWindow('https://jdejesus001.medium.com/a-short-introduction-to-generative-ai-part-2-of-3-ea53e17a4d2f')}>A Brief Introduction to Generative AI — Part 2 of 3</div></li>
                            <li><div className="article" onClick={()=>this.openWindow('https://jdejesus001.medium.com/a-short-introduction-to-generative-ai-part-3-of-3-fedca8bf9a16')}>A Brief Introduction to Generative AI — Part 3 of 3</div></li>
                            <li><div className="article" onClick={()=>this.openWindow('https://jdejesus001.medium.com/what-is-machine-learning-ac2f14dea42e')}>What is Machine Learning?</div></li>
                            <li><div className="article" onClick={()=>this.openWindow('https://jdejesus001.medium.com/ai-ethical-concerns-a-call-to-action-2ac55e58c61a')}>AI &amp; Ethical Concerns: A Call to Action</div></li>
                        </ul>

                        <div className="artcategory">Business Automation</div>
                        <ul>
                            <li><div className="article" onClick={()=>this.openWindow('https://jdejesus001.medium.com/an-overview-of-turbonomic-34bff85fcb7d')}>An Overview of Turbonomic</div></li>
                        </ul>
                        {/* <div className="draft" ><em>&nbsp;&nbsp;&#x2014;&nbsp;&nbsp;&nbsp;Hyperautomation Explained through the Lens of IBM Cloud Pak for Business Automation (DRAFT)</em>&nbsp;</div> */}
                        {/* <div className="draft" onClick={()=>this.openWindow('https://medium.com/@jdejesus001/hyperautomation-and-rpa-99738fdc26f3')}><em>&nbsp;&nbsp;&#x2014;&nbsp;&nbsp;&nbsp;Hyperautomation and RPA (DRAFT)</em>&nbsp;</div> */}

                        <div className="artcategory">Cloud</div>
                        {/* <div className="draft"><em>&nbsp;&nbsp;&#x2014;&nbsp;&nbsp;&nbsp;Using Red Hat OpenShift on the IBM Cloud, Essential Survival Skills</em>&nbsp;(DRAFT)</div> */}
                        {/* <div className="draft" onClick={()=>this.openWindow('https://medium.com/@jdejesus001/using-red-hat-openshift-on-the-ibm-cloud-70e60d196703')}><em>&nbsp;&nbsp;&#x2014;&nbsp;&nbsp;&nbsp;Using Red Hat OpenShift on the IBM Cloud, Essential Survival Skills</em>&nbsp;(DRAFT)</div> */}
                        <ul>
                            <li><div className="article" onClick={()=>this.openWindow('https://jdejesus001.medium.com/red-hat-openshift-essential-survival-skills-70e60d196703')}>Red Hat OpenShift Essential Survival Skills</div></li>
                            <li><div className="article" onClick={()=>this.openWindow('https://jdejesus001.medium.com/scalability-and-elasticity-for-virtual-application-patterns-in-ibm-pureapplication-system-cfd60decfb44')}>Scalability and Elasticity for Virtual Application Patterns in IBM PureApplication System</div></li>
                            <li><div className="article" onClick={()=>this.openWindow('https://jdejesus001.medium.com/navigating-the-ibm-cloud-d1a380f8fba8')}>Navigating the IBM Cloud with Workload Deployer and PureApplication Systems<br/><b>Part 1: A Primer on Cloud Technologies</b></div></li>
                            <li><div className="article" onClick={()=>this.openWindow('https://jdejesus001.medium.com/navigating-the-ibm-cloud-with-workload-deployer-and-pureapplication-systems-187d53cef6fb')}>Navigating
                                    the IBM Cloud with Workload Deployer and PureApplication Systems<br /><b>Part 2:
                                        Understanding Virtual System Patterns</b></div>
                            </li>
                            <li><div className="article" onClick={()=>this.openWindow('https://jdejesus001.medium.com/navigating-the-ibm-cloud-with-workload-deployer-and-pureapplication-systems-6d6bec003da7')}>Navigating
                                    the IBM Cloud with Workload Deployer and PureApplication
                                    Systems<br /><b>Part 3: Creating Script Packages</b></div>
                            </li>
                            <li><div className="article" onClick={()=>this.openWindow('https://jdejesus001.medium.com/navigating-the-ibm-cloud-with-workload-deployer-and-pureapplication-systems-d8e8333f7245')}>Navigating
                                    the IBM Cloud with Workload Deployer and PureApplication Systems
                                    <br /><b>Part 4: Creating Plug-Ins for Virtual Application Patterns</b></div>
                            </li>
                            <li><div className="article" onClick={()=>this.openWindow('https://jdejesus001.medium.com/how-where-and-why-ibm-pureapplication-fits-in-your-cloud-8d4e0363eb88')}>How,
                                    Where, and Why IBM PureApplication Fits in Your Cloud
                                    <br /><b>A Primer on Cloud Computing that Explains When PureApplication Makes
                                        Sense</b></div>
                            </li>
                            <li><div className="article" onClick={()=>this.openWindow('https://jdejesus001.medium.com/build-your-own-cloud-sandbox-20180149720e')}>Build
                                    Your Own Cloud Sandbox
                                    <br /><b>Part 1: Installing an IBM Workload Deployer Virtual Appliance</b></div>
                            </li>
                            <li><div className="article" onClick={()=>this.openWindow('https://jdejesus001.medium.com/build-your-own-cloud-sandbox-e09e1b25d350')}>Build
                                    Your Own Cloud Sandbox
                                    <br /><b>Part 2: Configuring Security Options</b></div>
                            </li>
                            <li><div className="article" onClick={()=>this.openWindow('https://jdejesus001.medium.com/build-your-own-cloud-sandbox-711184f8bb7f')}>Build
                                    Your Own Cloud Sandbox
                                    <br /><b>Part 3: Defining Cloud Resources</b></div>
                            </li>
                        </ul>
                        <div className="artcategory">Development</div>
                        <ul>
                           <li><div className="article" onClick={()=>this.openWindow('https://jdejesus001.medium.com/extending-a-pie-chart-react-component-with-front-end-dynamic-capabilities-acdceec9dfe')}>Extending a Pie Chart React Component with Front-end Dynamic Capabilities</div></li>
                           <li><div className="article" onClick={()=>this.openWindow('https://jdejesus001.medium.com/extending-a-bar-chart-react-component-with-front-end-dynamic-capabilities-dab9d2c229a5')}>Extending a Bar Chart React Component with Front-end Dynamic Capabilities</div></li>
                        </ul>
                        <div className="artcategory">DevOps</div>
                        <ul>
                            <li><div className="article" onClick={()=>this.openWindow('https://jdejesus001.medium.com/simplifying-rational-build-forge-projects-with-an-external-control-file-72f7537f7e9')}>Simplifying
                                        Rational Build Forge Projects With an External Control File</div>
                            </li>
                        </ul>
                        <div className="artcategory">Integration</div>
                        <ul>
                            <li><div className="article" onClick={()=>this.openWindow('https://developer.ibm.com/?q=Enabling%20SCA-MQ%20Integration%20via%20MQ%20Bindings')}>Enabling
                                    SCA-MQ Integration via MQ Bindings
                                    <br /></div>
                            </li>
                        </ul>
                        <div className="artcategory">Other</div>
                        <ul>
                            <li><div className="article" onClick={()=>this.openWindow('https://jdejesus001.medium.com/a-call-to-action-lets-close-the-digital-gap-ea1ccecf5aa5')}>A
                                        Call to Action: Let’s Close the Digital Gap<br/><b>Making the Web More Accessible to the
                                        Elderly and Disabled</b></div>
                            </li>
                            <li><div className="article" onClick={()=>this.openWindow('https://jdejesus001.medium.com/occupational-ergonomics-basics-7c8a7556a189')}>Occupational
                                    Ergonomics Basics<br/><b>Avoiding Serious Back Injuries</b></div>
                            </li>
                        </ul>
                        <br />
                        <div className="artcategory">Personal Growth</div>
                        <ul>
                            <li><div className="article" onClick={()=>this.openWindow('https://jdejesus001.medium.com/timeboxing-for-resilience-40c559b39e7e')}>Timeboxing
                                    for Resilience</div>
                            </li>
                        </ul>
                    </div>
                    </div>
            </>

        );
    }
}

export default JDArtEng;