import React, * as react from 'react';
import './JDCerts.css';

class JDCerts extends react.Component {

    openWindow(url) {
        return window.open(url, "Popup",
            "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=1200, height=700, top=30"
        );
    };
    render() {
        return (
            <>
                <div className="container-fluid container-md">
                    <div className="pagetitle">Additional Credentials</div>
                    <div className="fpmaintext">

                        <div className="artcategory">Certifications</div>
                        <div className="grid-container">
                            <a href="#!" onClick={() => this.openWindow('/images/mitcert.png')}><div className="cert"><img width='185px' height='150px' src='./images/mitcert.png' alt="mitcert" /></div></a>
                            <a href="#!" onClick={() => this.openWindow('/images/scitanormal.jpg')}><div className="cert"><img width='205px' height='150px' src='./images/scitasmall.jpg' alt="scita" /></div></a>
                            <a href="#!" onClick={() => this.openWindow('/images/itanormal.jpg')}><div className="cert"><img width='215px' height='150px' src='./images/itasmall.jpg' alt="ita" /></div></a>
                            <a href="#!" onClick={() => this.openWindow('/images/aotcert.jpg')}><div className="cert"><img width='205px' height='150px' src='./images/aotcert.jpg' alt="aot" /></div></a>
                            <a href="#!" onClick={() => this.openWindow('/images/tkdcert.jpg')}><div className="cert"><img width='220px' height='150px' src='./images/tkdcert.jpg' alt="tkd" /></div></a>
                        </div>
                        <div className="artcategory">Certificates</div>
                        <div className="grid-container">
                            <a href="#!" onClick={() => this.openWindow('/images/sytyci.jpg')}><div className="cert"><img width='220px' height='150px' src='./images/sytyci.jpg' alt="sytci" /></div></a>
                            <a href="#!" onClick={() => this.openWindow('/images/iamot.jpg')}><div className="cert"><img width='205px' height='150px' src='./images/iamot.jpg' alt="iamot" /></div></a>
                            <a href="#!" onClick={() => this.openWindow('/images/dworks.jpg')}><div className="cert"><img width='120px' height='150px' src='./images/dworks.jpg' alt="dworks" /></div></a>
                        </div>


                    </div>
                </div>
            </>
        );
    }
}

export default JDCerts;