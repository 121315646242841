import React, * as react from 'react';
import './JDAnim.css'

class JDAnim extends react.Component {

    openWindow(url) {
        return window.open(url, "Popup",
            "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=1200, height=700, top=30"
        );
    };

    render() {

        return (
            <>
                <div className="container-fluid container-md">
                    <div className="pagetitle">Animated Videos</div>
                    <div className="fpmaintext">

                        <div className="grid-container">
                            <a href="#!" onClick={() => this.openWindow('./videos/JDBLUEMIX.mp4')}><div className="anim"><img width='230px' height='150px' src='/images/bmixvideo.jpg' alt="aot" /><p>&nbsp;</p>I created this explainer video as a companion resource to a developerWorks article I wrote.<br/>&nbsp;<br/>&nbsp;</div></a>
                            <a href="#!" onClick={() => this.openWindow('./videos/IBMAOT-EXTERNAL.mp4')}><div className="anim"><img width='220px' height='150px' src='/images/aotvideo.jpg' alt="aot" /><p>&nbsp;</p>I created an internal and external version of this video to introduce the IBM Academy of Technology to the rest of IBM, and to the world.</div></a>
                            <a href="#!" onClick={() => this.openWindow('./videos/IBMSWAdoption-Chet.mp4')}><div className="anim"><img width='220px' height='150px' src='/images/i4ivideo.jpg' alt="i4i" /><p>&nbsp;</p>As the Technical Vitality lead for the IBM SW Adoption team, I created this video to introduce our team and highlight our technical pillars of expertise.</div></a>
                        </div>


                    </div>
                </div>
            </>
        );

        // return (
        //     <>
        //         <div className="container-fluid container-md">
        //             <div className="pagetitle">Books</div>
        //             <div>
        //                 <p><br/>
        //                     <ul>
        //                         {/* <li><div className="article" onClick={() => this.openWindow('https://jdejesus001.medium.com/what-is-machine-learning-ac2f14dea42e')}>RedBook SME Contribution - Creating Composite Application Patterns Models for IBM PureApplication System</div></li> */}
        //                         <li><div className="article"><em>RedBook SME Contribution</em> - Creating Composite Application Patterns Models for IBM PureApplication System</div></li>
        //                         <li><div className="article"><em>RedBook SME Contribution</em> - Establishing a Secure Hybrid Cloud with the IBM PureApplication Family</div></li>
        //                         <li><div className="article">Thomas Jell, (Contributing Authors: Jos&eacute; De Jes&uacute;s, et al): CUC 96 Component Based Software Engineering, in: Book, Cambridge University Press, 1998,
        //                             ISBN/ISSN: 0-521-64821-1</div></li>
        //                         <li><div className="article">Jos&eacute; De Jes&uacute;s, Abad Godoy: Borland Pascal With Objects 7.0, in: Book, MIS:Press, 704 pages, 1993, ISBN/ISSN: 1-55828-247-5</div></li>
        //                     </ul>
        //                 </p>
        //             </div>
        //         </div>
        //     </>

        // );
    }
}

export default JDAnim;