import React, * as react from 'react';
import badges from './images/earnedbadges.png';
import credly from './images/credlylogo.png';
import './JDDigital.css';

class JDDigital extends react.Component {
    // credly.com/users/jose-de-jesus
    //  http://www.linkedin.com/in/jdejesus-ibm

    openWindow(url) {
        return window.open(url, "Popup",
           "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=1200, height=700, top=30"
        );
    };
    render() {
        return (
            <>
            <div className="container-fluid container-md">
               <div className="pagetitle">Digital Badges</div>
               <div className="credly">
                  <div onClick={() => this.openWindow('http://credly.com/users/jose-de-jesus')} id="sidebarToggle">Confirm badges in <img width="30px" height="18px" alt="credly" src={credly} /></div>
               </div>
               <div className="badges">
                  <img alt="badges" src={badges} />
               </div>
            </div>
            </>
        );
    }
}

export default JDDigital;