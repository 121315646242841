import React, * as react from 'react';
import './JDAcademic.css';

class JDAcademic extends react.Component {

    openWindow(url) {
        return window.open(url, "Popup",
           "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=1200, height=700, top=30"
        );
    };
    render() {
        return (
            <>
            <div className="container-fluid container-md">
                    <div className="pagetitle">Academic Background</div>
                    <div className="fpmaintext">

                        <div className="artcategory">University of Miami&nbsp;&nbsp;<span>Miami, Florida</span></div>
                        <ul>
                            <li><div className="degree" onClick={()=>this.openWindow('./images/UMDiploma.jpg')}>Master of Science (M.S.) in Management of Technology<br/>
                            <span>August, 2007</span></div></li>
                        </ul>
                        <div className="artcategory">Fordham University&nbsp;&nbsp;<span>Bronx, New York</span></div>
                        <ul>
                            <li><div className="degree" onClick={()=>this.openWindow('./images/Fordham.jpg')}>Bachelor of Arts (B.A.) in Computer Science<br/>
                            <span>May, 1989</span></div></li>
                        </ul>

                        
                    </div>
                </div>
            </>
        );
    }
}

export default JDAcademic;